.header {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px 20px;
  background-color: #F0EEF3;
  width: 100%;
  position: relative;
  gap: 20px;
}

.logo img {
  max-height: 60px;
}

.navigation ul {
  list-style: none;
  display: flex;
  gap: 30px;
}

.navigation ul li a {
  text-decoration: none;
  color: #4c5b69;
  font-weight: 600;
  font-size: 16px;
  transition: color 0.3s ease; /* Smooth transition for hover effect */
}


.navigation ul li a:hover {
  color: #34404a; /* Color for active link and hover state */
  font-weight: bold; /* Bold text for active link */
}

.header-buttons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.instagram-icon {
  font-size: 24px;
  color: #C13584;
  transition: color 0.3s ease;
}

.instagram-icon:hover {
  color: #E1306C;
}

.hire-us-button {
  padding: 10px 20px;
  border: 2px solid #a4b7a6;
  border-radius: 15px;
  color: #8b9f8e;
  text-decoration: none;
  font-weight: 600;
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  gap: 5px;
  z-index: 10;
}

.hamburger .bar {
  width: 25px;
  height: 3px;
  background-color: #4c5b69;
  border-radius: 2px;
}

/* Media Query for Mobile View */
@media screen and (max-width: 768px) {
  .header {
    flex-direction: row; 
    justify-content: space-between; 
    padding: 10px; 
    width: auto;
  }

  .navigation {
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #F0EEF3;
    flex-direction: column;
    align-items: center;
    gap: 15px; 
    padding: 20px 0; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    z-index: 9; 
  }

  .navigation ul {
    flex-direction: column; 
    width: 100%;
    align-items: center;
    padding: 0; 
    margin: 0; 
    gap: 10px;
  }

  .navigation ul li {
    width: 100%; 
    text-align: center; 
    padding: 5px 0; 
  }

  .navigation ul li a {
    font-size: 18px; 
    padding: 10px 0;
    width: 100%; 
    display: block;
    color: #4c5b69;
  }

  .navigation.open {
    visibility: visible;
    opacity: 1;
  }

  /* Ensure the Instagram icon is visible outside the hamburger menu */
  .header-buttons {
    display: flex;
    position: absolute;
    top: 25px;
    right: 60px;
  }
  .hire-us-button{
    padding: 5px 10px;
  }

  .hamburger {
    display: flex;
  }
}
