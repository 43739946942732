body {
  background-color: #F0EEF3; 
  margin: 0;
  font-family: 'Arial', sans-serif;
  overflow-x: hidden;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* WhatsApp Icon Styles */
.whatsapp-float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background-color: #25D366;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
}

.whatsapp-float i {
    font-size: 28px; /* Adjusts the icon size */
}

.whatsapp-float:hover {
    box-shadow: 2px 2px 10px #999;
}

/* Mobile View Adjustments */
@media screen and (max-width: 768px) {
    .whatsapp-float {
        width: 50px;
        height: 50px;
        bottom: 20px;
        right: 20px;
        font-size: 24px;
    }

    .whatsapp-float i {
        font-size: 24px;
    }
}
