.creativity-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 60px 20px;
  background-color: #f8f9fc;
  gap: 10px;
  overflow: hidden;
}

.creativity-images {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: repeat(3, 1fr);
  gap: 10px;
  max-width: 50%;
}

.gallery-img {
  border-radius: 10px;
  object-fit: cover;
  position: absolute;
}

/* First image spanning two rows and two columns */
.gallery-img1 {
  /* grid-column: 1 / span 2;
  grid-row: 1 / span 2; */
  width: 20%;
  height: 55%;
  bottom: -1980px;

}

/* Second image placed on top of the first image */
.gallery-img2 {
  grid-column: 3 / span 1;
  grid-row: 1 / span 1;
  left: 143px;
  bottom: -1975px;
  width: 11%;
  height: 23%;
  z-index: 5;
}

.empty-cardz{
  background-color: #f8f9fc;
  border-radius: 10px 0px 0px 0px;
  /* box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1); */
  position: absolute;
}

.empty-card-x{
  grid-column: 3 / span 1;
  grid-row: 1 / span 1;
  color: #090909;
  left: 132px;
  bottom: -1980px;
  width: 12.5%;
  height: 25%;
}

/* Third image taking full height in the middle column */
.gallery-img3 {
  grid-column: 3 / span 1;
  grid-row: 2 / span 2;
  left: 335px;
  bottom: -1980px;
  width: 13%;
  height: 55%;
}

/* Fourth and fifth images in the last row */
.gallery-img4 {
  width: 13%;
  bottom: -1980px;
  right: 738px;
  height: 27%;
}

.gallery-img5 {
  width: 13%;
  bottom: -1760px;
  right: 738px;
  height: 27%;
}

.gallery-img4 {
  grid-column: 1 / span 1;
  grid-row: 3 / span 1;
}

.gallery-img5 {
  grid-column: 2 / span 1;
  grid-row: 3 / span 1;
}

.empty-cards {
  background-color: #F0EEF3;
  border-radius: 0px 0px 0px 10px;
  /* box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1); */
  position: absolute;
}

.empty-card-t{
  width: 45.2%;
  height: 33%;
  top: 2290px;
}

.creativity-content {
  max-width: 45%;
  text-align: left;
}

.creativity-content h2 {
  font-size: 2.5rem;
  font-weight: 700;
  color: #2c3e50;
  margin-bottom: 20px;
  right: -20px;
  top: -50px;
  position: relative;
  z-index: 5;
}

.creativity-content p {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
  margin-bottom: 30px;
  right: -20px;
  top: -50px;
  position: relative;
  z-index: 3;
}

.stats {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.stat-item {
  text-align: center;
}

.stat-item h3 {
  font-size: 2rem;
  font-weight: 700;
  color: #2c3e50;
  margin-bottom: 10px;
}

.stat-item p {
  font-size: 1rem;
  color: #555;
}


