.hero-section {
    padding: 80px 20px 20px 20px;
    width: auto;
    height: 116vh;
    overflow: hidden;
}

.hero-content {
    position: relative;
    z-index: 2; /* Ensure text is above the images */
    max-width: 45%; /* Reduce width to prevent overflow */
    padding-right: 20px;
    left: 100px;
    flex: 1; /* Allow flex-grow to make content responsive */
}

.hero-content h1 {
    font-size: 90px;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 0px;
    margin-top: 0px;
    /* background-color: #f7f7f7; Match the background color */
    display: inline-block;
    padding: 5px;
    position: relative;
    z-index: 3;
    top: 15px; /* Adjust this value to control overlap */
    left: 0;
    right: -250px;
}

.hero-content p {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.6;
    color: #555;
    margin-bottom: 0px;
    /* background-color: #f7f7f7; Match the background color */
    padding: 0px;
    display: inline-block;
    position: relative;
    text-align: justify;
    z-index: 2;
    top: 20px;
    left: 0;
}

.cta-button {
    padding: 18px 30px;
    font-size: 1.2rem;
    color: #fff;
    background-color: #748d75; /* Adjusted to match the color in the image */
    border: none;
    border-radius: 15px; /* Adjusted for more rounded corners */
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2); /* Increased shadow for more depth */
    display: inline-block;
    margin-top: 20px;
    position: relative;
    top: 200px; /* Reset vertical positioning */
    left: 0;
}

.cta-button:hover {
    background-color: #62715f; /* Slightly darker shade for hover effect */
}

.hero-images {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 802px; /* Adjust width to prevent overflow */
    position: relative;
    top: -600px;
    left: 500px;
    /* margin-left: auto; */
}

.hero-images img {
    width: 100%;
    height: 50%;
    border-radius: 20px;
    padding-top: 0px;
}

.empty-card {
    background-color: #F0EEF3;
    /* border-radius: 0px 15px 15px 0px; */
    /* box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1); */
    position: absolute;
    z-index: 1;
    width: 250px; /* Adjust width as necessary */
    height: 150px; /* Adjust height as necessary */
}

.empty-card-top {
    top: 0px;
    right: 550px;
    border-radius: 0px 0px 10px 0px;
}

.empty-card-bottom {
    width: 280.11px;
    height: 287.92px;
    border-radius: 0px 15px 15px 0px;
    top: 270px;
    padding-bottom: 50px;
    left: -18px;
}

.secondary-images {
    display: flex;
    gap: 20px;
    position: relative;
    left: 80px;
}

.secondary-images img {
    width: 100%;
    height: auto;
    border-radius: 20px;
}

