.experience-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px 60px 20px;
    background-color: #f7f7f7;
    width: 100%; /* Prevent horizontal scroll */
    overflow: hidden;
  }
  
  .experience-images {
    flex: 1;
    max-width: 50%;
    position: relative;
  }
  
  .image-container {
    position: relative;
    width: 100%;
  }
  
  .main-image {
    width: 90%;
    height: auto;
    border-radius: 20px;
    position: relative;
    padding-left: 80px;
    padding-top: 50px;
  }
  
  
  .experience-content {
    flex: 1;
    max-width: 45%;
    padding-right: 100px;
  }
  
  .experience-content h3 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #8ebf42;
    margin-bottom: 10px;
  }
  
  .experience-content h2 {
    font-size: 2.5rem;
    font-weight: 700;
    color: #34404a;
    margin-bottom: 20px;
  }
  
  .experience-content p {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 30px;
    line-height: 1.6;
  }
  
  .cta-buttons {
    padding: 15px 30px;
    font-size: 1.2rem;
    color: #fff;
    background-color: #748d75;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  }
  
  .cta-buttons:hover {
    background-color: #62715f;
  }
  