/* Global Styles */
body {
  font-family: 'Times New Roman', serif;
  margin: 0;
  padding: 0;
  background-color: #F0EEF3;
  color: #333;
}

/* Unique About Page */
/* .unique-about-page {
  padding: 20px;
} */

/* My Story Section */
.unique-my-story-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 5px;
  background-color: #F0EEF3;
}

.unique-content-wrapper {
  display: flex;
  align-items: flex-start;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  gap: 40px;
}

.unique-image-container {
  flex: 1;
}

.unique-profile-image {
  width: 100%;
  max-width: 500px;
  height: auto;
  border-radius: 5px;
}

.unique-text-container {
  flex: 1.5;
  max-width: 600px;
}

.unique-about-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333;
}

.unique-about-text {
  font-size: 1.2rem;
  line-height: 1.8;
  margin-bottom: 40px;
}

.unique-portfolio-button {
  padding: 15px 30px;
  font-size: 1rem;
  background-color: #d3b79b;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 5px;
}

.unique-portfolio-button:hover {
  background-color: #b6967f;
}

/* Crafting Memories Section */
.unique-crafting-memories-section {
  padding: 50px 0;
  background-color: #ffffff;
}

.unique-crafting-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}

.unique-crafting-text-container {
  flex: 1;
  padding: 20px;
}

.unique-section-title {
  font-size: 1.5rem;
  color: #d3b79b;
  text-transform: uppercase;
  margin-bottom: 10px;
  letter-spacing: 2px;
}

.unique-section-subtitle {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333;
}

.unique-crafting-text {
  font-size: 1.2rem;
  line-height: 1.8;
  margin-bottom: 40px;
  background-color: #F0EEF3;
  padding: 20px;
  border-radius: 5px;
}

.unique-book-now-button {
  padding: 15px 30px;
  font-size: 1rem;
  background-color: #d3b79b;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 5px;
}

.unique-book-now-button:hover {
  background-color: #b6967f;
}

.unique-crafting-images {
  flex: 1;
  position: relative;
}

.unique-crafting-image {
  width: 50%; /* Ensure images take full width in their container */
  max-width: 400px;
  height: auto;
  border-radius: 5px;
}

.unique-crafting-image-1 {
  position: absolute;
  top: 200px;
  right: -50px;
}

.unique-crafting-image-2 {
  position: absolute;
  bottom: -450px;
  right: 280px;
}

/* Media Queries */
@media screen and (max-width: 1024px) {
  .unique-content-wrapper {
      flex-direction: column;
      align-items: center;
  }

  .unique-image-container, .unique-text-container {
      max-width: 100%;
      text-align: center;
  }

  .unique-profile-image {
      margin-bottom: 20px;
      max-width: 100%; /* Make the profile image more responsive */
  }

  .unique-about-title {
      text-align: center;
  }

  .unique-crafting-content {
      flex-direction: column-reverse;
      align-items: center;
  }

  .unique-crafting-images {
      position: static;
      margin-top: 20px;
      margin-left: 20px;
  }

  .unique-crafting-image {
      width: 40%; /* Make crafting images more responsive */
      max-width: none;
  }

  .unique-crafting-image-1,
  .unique-crafting-image-2 {
      position: relative;
      right: auto;
      top: auto;
      bottom: auto;
  }

  .unique-crafting-image-1 {
      margin-bottom: 20px;
      margin-left: 20px;
  }
  .unique-book-now-button{
    margin-left: 115px;
  }
}

@media screen and (max-width: 768px) {
  .unique-profile-image {
      max-width: 100%; /* Further reduce for smaller screens */
  }

  .unique-crafting-image {
      width: 40%; /* Further reduce for smaller screens */
  }

  .unique-book-now-button{
    margin-left: 115px;
  }
}

@media screen and (max-width: 480px) {
  .unique-profile-image {
      max-width: 100%; /* Further reduce for smallest screens */
  }

  .unique-crafting-image {
      width: 40%; /* Further reduce for smallest screens */
      margin-left: 20px;
  }

  .unique-book-now-button{
    margin-left: 115px;
  }
}
