/* Existing styles */
.gallery-page .hero-section {
  background-image: url('../assets/1.jpg'); /* Replace with your hero image */
  background-size: cover;
  background-position: center;
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 3rem;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.6);
}

.gallery-page .filter-section {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.filter-list {
  display: flex;
  gap: 20px;
  list-style-type: none;
  padding: 0;
}

.filter-list li {
  cursor: pointer;
  font-size: 1.2rem;
  padding: 10px 20px;
  border: 2px solid #748d75;
  border-radius: 5px;
  background-color: #f7f7f7;
  transition: all 0.3s ease;
}

.filter-list li:hover, .filter-list li.active {
  background-color: #748d75;
  color: white;
}

.custom-dropdown {
  position: relative;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  background-color: #f7f7f7;
  border: 2px solid #748d75;
  border-radius: 10px;
  cursor: pointer;
  user-select: none;
  transition: all 0.3s ease;
}

.dropdown-selected {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  font-size: 1.2rem;
  color: #4c5b69;
}

.dropdown-selected .arrow-down {
  width: 0; 
  height: 0; 
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #4c5b69;
}

.custom-dropdown.open .arrow-down {
  transform: rotate(180deg);
}

.dropdown-options {
  display: none;
  position: absolute;
  width: 100%;
  top: 100%;
  left: -20px;
  background-color: #fff;
  border: 2px solid #748d75;
  border-radius: 10px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 100;
  max-height: 200px;
  overflow-y: auto;
  transition: all 0.3s ease;
}

.custom-dropdown.open .dropdown-options {
  display: block;
}

.dropdown-options li {
  padding: 10px 15px;
  font-size: 1.1rem;
  color: #4c5b69;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.dropdown-options li:hover {
  background-color: #748d75;
  color: white;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 10px;
  padding: 20px 20px;
}

.image-item {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.image-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.image-item:hover img {
  transform: scale(1.1);
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.5rem;
}

.image-item:hover .overlay {
  opacity: 1;
}

/* Modal styles */
.modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: -20px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Dark background to focus on the image */
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 20px; */
  cursor: zoom-out; /* Indicate that clicking will close the modal */
}

.modal img.full-image {
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

/* Mobile View Styles */
@media (max-width: 768px) {
  .gallery-page .hero-section {
    height: 30vh;
    font-size: 2rem;
  }

  .gallery-page .filter-section {
    justify-content: flex-start; /* Align the dropdown to the start */
    margin: 10px 20px; /* Adjust margin */
  }

  .custom-dropdown {
    width: 100%;
  }

  .image-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 5px;
    padding: 10px;
  }

  .overlay {
    font-size: 1rem;
  }

  .modal img.full-image {
    max-width: 100%;
    max-height: 80%;
    object-fit: contain;
    border-radius: 5px;
  }
}
