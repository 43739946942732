.expertise-section {
  padding: 60px 20px;
  background-color: #F0EEF3;
  text-align: center;
}

.expertise-section h2 {
  font-size: 2.5rem;
  font-weight: 700;
  color: #34404a;
  margin-bottom: 40px;
}

.expertise-carousel {
  display: flex;
  gap: 20px;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  padding-bottom: 20px;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.expertise-carousel::-webkit-scrollbar {
  display: none;  /* Chrome, Safari, and Opera */
}

.expertise-card {
  background-color: #F0EEF3;
  border-radius: 10px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  padding: 20px;
  min-width: 280px;
  scroll-snap-align: start;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.expertise-card img {
  width: 120px;
  height: auto;
  margin-bottom: 20px;
}

.expertise-card h3 {
  font-size: 1.3rem;
  font-weight: 600;
  color: #34404a;
  margin-bottom: 10px;
}

.expertise-card p {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
}

.expertise-card:hover {
  transform: translateY(-10px);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
}

/* Visual Cues for Scrolling */
.expertise-carousel::before,
.expertise-carousel::after {
  content: '';
  display: block;
  width: 50px;
  height: 100%;
  position: absolute;
  top: 0;
  pointer-events: none;
  background: linear-gradient(to right, rgba(240, 238, 243, 1), rgba(240, 238, 243, 0));
  z-index: 1;
}

.expertise-carousel::after {
  right: 0;
  transform: rotate(180deg);
}

.expertise-carousel::before {
  left: 0;
}
